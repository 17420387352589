<template>
  <div>
    <div class="logo-wrapper">
      <logo :imgPath="getCompanyInfo.logoPath" />
    </div>
    <b-card-text class="mb-25">
      {{ getCompanyInfo.full_name }}
    </b-card-text>
    <b-card-text class="mb-25">
      {{ getCompanyInfo.address }}
    </b-card-text>
    <b-card-text class="mb-0">
      {{ getCompanyInfo.contact }}
    </b-card-text>
  </div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import {
  BCardText,
} from "bootstrap-vue";
export default {
  name: "InvoiceDetails",
  components: {
    BCardText,
    Logo,
  },
  computed: {
    getCompanyInfo() {
      let companyInfo = localStorage.getItem("COMPANY_INFO");
      return JSON.parse(companyInfo);
    },
  },
};
</script>
<style></style>
